@font-face {
  font-family: "ErasDemiITC";
  src: url("../fonts/ERASDEMI.TTF");
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "ErasDemiITC";
}

body {
  background-color: #edf5fb !important;
  color: #525253;
  font-family: "ErasDemiITC", sans-serif;
}

.logo {
  width: 14.5rem;
  transition: width 0.3s;
}

.nav .dropdown-toggle::after {
  content: none !important;
}

.nav .dropdown,
.dropend,
.dropstart,
.dropup {
  width: 150px;
}

.nav .handburger {
  color: #000;
  margin-top: 4.5px;
  margin-right: 15px;
  transform: scale(2.5);
  transition: display 0.3s;
}

.nav_img {
  display: flex;
  justify-content: end;
  align-items: center;
}

.sidebar {
  position: fixed;
  width: 17rem;
  background-color: white;
  height: calc(100vh - 65.94px);
  transition: margin-left 0.3s;
  z-index: 999;
}

.sidebar .list-group a {
  text-decoration: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  transition: padding-left 0.3s, background-color 0.2s, border-left 0.1s;
}

.sidebar .list-group a.active {
  border-left: 3px solid #334d6e;
  padding-left: 1rem;
  color: #334d6e;
  background: #f4f9ff;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: "Calibri";
}

.sidebar .list-group .list-group-item {
  padding: 15px 10px;
  color: #334d6e;
  background: transparent;
  font-weight: 600;
  border: 0;
}

.sidebar_toggle {
  position: absolute;
  margin-left: 0rem !important;
  width: 100vw;
}

.pos .accordion-button {
  color: #0c63e4;
  background-color: #e7f1ff;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.header h4 {
  font-weight: 700;
}

.header a {
  font-size: 0.8rem;
}

.main_section {
  margin-top: 65.94px;
  margin-left: 17rem;
  height: calc(100vh - 65.94px);
  width: calc(100vw - 17rem);
  position: relative;
  overflow-x: hidden;
  transition: margin-left 0.3s;
}

.p-btn {
  font-family: sans-serif;
  color: white;
  background-color: rgb(0, 160, 228);
  border: 0;
  appearance: none;
}

.pos_filter {
  display: flex;
  flex-direction: column;
  height: 3.5rem;
  background-color: #ebeff2;
}

.pos_filter label {
  font-size: 12px;
  color: #000;
  font-weight: 700;
}

.pos_filter input,
.pos_filter select {
  outline: none;
  border: none;
  background-color: transparent;
  display: block;
  line-height: 1.2em;
  font-size: 10pt;
}

.pos_filter_btn {
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 2;
  width: 10rem;
  color: black;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 40px;
  text-transform: uppercase;
  border: 1px solid black;
}

.primary_btn {
  background-color: #2196f3;
  color: white;
  border: none;
}

.pos .title {
  font-weight: 700;
}

.accordion-flush .accordion-collapse {
  margin-top: -1rem;
}

.form .col input,
.form .col select,
.form .col .searchable-select .css-319lph-ValueContainer {
  height: 55px;
}

.form .col .searchable-select {
  font-family: calibri;
}

.form .col .searchable-select .css-1insrsq-control {
  background-color: white;
}

.form .col input,
.form .col textarea,
.form .col select {
  border-radius: 3px;
  border: 0.5px solid #d3d3d3;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  outline: none;
  font-family: Calibri, sans-serif;
  font-size: 18px;
}

.form .col input[type="radio"] {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Chrome, Safari, Opera */
  transform: scale(1.5);
  cursor: pointer;
}

.form .col span {
  font-size: 16px;
  color: #656565;
  font-family: "calibri";
  font-weight: bold;
  background: #fff;
}

.form .col .searchable-select {
  height: 55px;
  border-radius: 3px;
}

.form .col .searchable-select .css-1s2u09g-control {
  height: inherit;
  border-radius: 10px;
  padding: 0;
}

.form .col .searchable-select .css-1insrsq-control {
  border-radius: 10px;
}

.form .col .searchable-select .css-1s2u09g-control .css-6j8wv5-Input {
  grid-area: 1/1/1/1;
  margin: 0;
  padding: 0;
}

.form .col label {
  color: #00a0e3 !important;
  font-size: 15px !important;
  top: -1.5rem;
  margin-left: 10px;
  max-width: 90%;
  left: 0;
  background-color: white;
  padding: 0.4rem;
  font-family: "calibri";
  font-weight: bold;
}

h6,
label {
  font-family: "calibri";
  font-weight: bold;
}

.form button {
  width: 150px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: normal;
}

.p-error {
  font-size: 0.7rem;
  color: red;
  font-family: "Calibri";
  margin: 10px 5px;
}

.file-btn {
  width: 140px !important;
  height: 40px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  align-items: center !important;
  margin-left: 0.8rem;
}

.file-choose label {
  font-size: 12px;
  font-weight: 100;
}

.img-preview {
  width: 200px;
  min-height: 190px;
  padding: 10px;
  position: relative;
  background: #eee;
  border: 2px dashed #949494;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
}

.img-preview img {
  height: -webkit-fill-available;
  max-width: 100%;
  object-fit: contain;
  margin: auto;
  display: block;
}

.img-preview .clear {
  position: absolute;
  right: -32px;
  top: -30px;
  height: 60px;
  width: 60px;
  cursor: pointer;
}

.react-datepicker__input-container input {
  width: 100%;
}

.table_paginate {
  width: 100%;
  display: flex;
  justify-content: end;
}

.table_paginate ul li a {
  font-family: sans-serif;
}

.agreement_download_btn {
  text-decoration: none;
}

.agreement_text {
  font-size: 13px !important;
}

.__loading {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  /* width: calc(100vw - 15rem); */
}

.__loading h1 {
  font-size: 1.3rem;
}

.number {
  font-weight: 400;
  font-size: 15px;
  color: #525253;
  padding-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.label-text {
  font-weight: 400;
  font-size: 15px !important;
  color: #525253 !important;
}

.input-disabled {
  border-top: solid 1px #ccc !important;
  background-color: #f3f3f3 !important;
  color: #949494 !important;
  cursor: not-allowed !important;
  font-family: "Calibri" !important;
}

.toplabel-disabled {
  background-color: #f3f3f3 !important;
  cursor: not-allowed !important;
  top: -1rem !important
}

.cattle-insurance .react-datepicker__navigation-icon--next::before {
  left: 25px !important;
}

.cattle-insurance .react-datepicker__navigation-icon--previous::before {
  right: 25px;
}

.radio-label-text {
  font-size: 18px !important;
  color: #29166F !important;
  font-weight: 400 !important;
  font-weight: bold !important;
}

.label-amount {
  font-size: 14px;
  font-family: Calibri;
}
.maxWidth-section .react-datepicker-wrapper{
  max-width: 50%;
  margin: auto;
}
 .maxWidth-section .status-sec {
  max-width: 70%;
  margin: auto;
 }

@media (max-width: 768px) {
  body {
    font-size: 0.8rem;
  }

  .logo {
    width: 8rem;
  }

  .sidebar {
    margin-left: -20rem;
  }

  .img-preview {
    width: 100%;
  }

  .main_section {
    margin-left: 0rem;
    width: 100vw;
  }

  .table_paginate {
    justify-content: center;
  }
}

.icueNavBarLst {
  padding: 0;
  list-style: none;
  margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.icueNavBarLst p{
  margin: 0;
}
.icueNavBarLst .accordion-button {
padding-left: 0.5rem;
}
.icueNavBarSubItm {
  width: 100%;
  position: relative;
  padding: 0.5rem 0;
  padding-left: 1rem;
  font-weight: 500;
  border-bottom: 0.5px solid lightgrey;
  font-family: 'Calibri';
  background: #f4f9ff;
  list-style-type: none !important;
  list-style: none !important;
}
.icueNavBarLink {
  display: block;
  color: #334d6e;
  text-decoration: none;
}
.icueNavSideBar .list-group a {
  border: 0 !important;
  font-weight: bold;
  font-family: 'ErasDemiITC';
}
@media (max-width: 1295px) {
  .form .col label {
    font-size: 12px !important;
    padding: 0;
  }
}


/* toggle switch */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  top: 4px;
  left: 6px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: rgb(195, 199, 198);
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  width: 15px;
  height: 15px;
  background-color: rgb(255, 254, 254);
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(20px);
  background-color: white;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: green;
}

 .setWidth {
   width: 75% !important;
   align-items: center;
 }
@media (min-width: 992px) {
    .row-cols-lg-3>*{
      width: 32%;
    }
    .row-cols-lg-4>* {
      width: 23%;
    }
    .row-cols-lg-2>* {
      width: 49%;
    }
}

 @media (max-width: 1200px) {
   .navBarResponsive {
     width: 80% !important;
   }

   .privacypolicy-chechbox {
     width: 100% !important;
   }

   .setWidth {
     width: 50% !important;
   }
 }

 @media (max-width: 999px) {
   .mainBodyContainer {
     margin-top: 11rem !important;
   }

   .custom-form-select {
     width: 30% !important;
   }

   .classesRow.rowWidth {
     width: 100% !important;
   }

   .ProductsAlign {
     float: none !important;
   }

   .pedVac {
     margin-top: 30px;
   }

   .insType {
     margin: 7.5px 0;
     width: 100%;
   }

   .setWidth {
     width: 100% !important;
   }
 }

 @media (max-width: 992px) {
   .navBarResponsive {
     width: 30% !important;
   }

   .barline {
     display: none;
   }

   .navBarResponsive {
     background-color: white;
   }

   .navBarResponsive .navBarResponsiveSection {
     display: block;
   }

   .navBarResponsive .navWidth {
     width: 100% !important;
     line-height: normal;
   }

   .navBarResponsive .navWidth .outbtn {
     width: 50% !important;
     margin-right: auto !important;
   }

   .navBarResponsive .navWidth .dropdown-menu {

     width: 100% !important;
     top: 22px !important;
     left: 4px !important;


   }

   .navWidth .nav-item {
     text-align: left;
     padding: 7px 20px;
     margin: 0 !important;

   }
 }

 @media (max-width: 780px) {
   .navBarResponsive {
     width: 40% !important;
   }
   
 }

 @media (max-width: 600px) {
   .parent_shit {
     margin-top: 45px !important;
   }

   .pcLogo {
     width: 10rem !important;
   }

   .statusText {
     font-size: 12px;
   }

   .userPic {
     width: 25px !important;
   }

   .pagination .page-link {
     font-size: 12px !important;
   }

   .mainHeading {
     font-size: 14px;
   }

   .addbtn,
   .addbtn i {
     font-size: 10px !important;
   }

   .btn.pos_filter_btn {
     font-size: 10px !important;
     padding: 0.4rem !important;
   }

   .paglabel span {
     font-size: 11px !important;
   }

   .icueNavBarLink {
     font-size: 12px;
   }

   .accordion .icueNavBarItm {
     border-bottom: 0;
     font-size: 12px !important;
   }

   .navBarResponsive {
     width: 50% !important;
   }

   .navHeight {
     height: 90px !important;
   }

   .Register__Pos__para span {
     width: auto
   }
 }

 @media (max-width: 480px) {
   .Register__Pos__para span {
     width: 50%
   }

   .pcLogo {
     width: 8rem !important;
   }

   .statusText {
     font-size: 10px;
   }

   .pos.cattle-insurance .radio-label-text {
     width: 60%;

   }
 }



 @media (max-width: 450px) {
   #responsive-navbar-nav {
     width: 100% !important;
   }

   .navBarResponsive {
     width: 100% !important;
   }

   .navBarResponsive .navBarResponsiveSection {
     display: flex;
   }

   .navBarResponsive .navWidth .outbtn {
     width: 100% !important;
     margin-left: unset !important;
   }

   .navBarResponsive .navWidth {
     width: 33.3% !important;
   }
 }

 @media (max-width: 400px) {
   .pcLogo {
     width: 6rem !important;
   }
 }

 .custom-file-input {
  color: transparent;
  width: 80px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Upload File';
  color: white;
  display: inline-block;
  background: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: #0445a5; 
}